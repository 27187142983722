export interface WekeyKeycapColor {
  base: string;
  background: string;
  border: string;
  label: string;
  note?: string;
}

export const WEKEY_KEYCAP_DEFAULT: WekeyKeycapColor = {
  base: '#f2ede4',
  background: '#fffef5',
  border: '#0000001a',
  label: '#333732',
};

const Keycap_Serika: WekeyKeycapColor[] = [
  {
    base: '#ece4d0',
    background: '#fffeea',
    border: '#0000001a',
    label: '#333732',
    note: 'Serika Alpha',
  },
  {
    base: '#ffcd00',
    background: '#fff848',
    border: '#0000001a',
    label: '#333732',
    note: 'Serika Mod',
  },
  {
    base: '#d16b6b',
    background: '#f58a89',
    border: '#0000001a',
    label: '#ece4d0',
    note: 'Serika Enter',
  },
];

const Keycap_Ob: WekeyKeycapColor[] = [
  {
    base: '#e5e4df',
    background: '#fffffa',
    border: '#0000001a',
    label: '#63696a',
    note: 'Ob Alpha',
  },
  {
    base: '#464746',
    background: '#545554',
    border: '#0000001a',
    label: '#c8c3b8',
    note: 'Ob Mod',
  },
  {
    base: '#950013',
    background: '#a81f20',
    border: '#0000001a',
    label: '#c8c3b8',
    note: 'Ob Enter',
  },
];

const WEKEY_KEYCAPS: WekeyKeycapColor[] = [
  // Custom
  WEKEY_KEYCAP_DEFAULT,
  {
    base: '#f09292',
    background: '#ffbab9',
    border: '#0000001a',
    label: '#333732',
    note: 'Wekey Enter',
  },
  ...Keycap_Ob,
  ...Keycap_Serika,
];

export const getWekeyKeycapColor = (baseColor: string) =>
  WEKEY_KEYCAPS.find((kc) => kc.base == baseColor) ?? null;
